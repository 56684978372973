.wrapper{
  margin-top: 100px;
}
.h1 {
  font-size: 40px;
}
.aboutUsContent p {
  font-size: 30px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  gap: 20px;
}
@media(max-width:2000px){
  .wrapper{
    margin-top: 0px;
  }
  .h1 {
    font-size: 30px;
  }
  .aboutUsContent p {
    font-size: 25px;
  }
}
@media(max-width:1500px){
  .wrapper{
    margin-top: 0px;
  }
  .h1 {
    font-size: 25px;
  }
  .aboutUsContent p {
    font-size: 20px;
  }
}
.dot{
  color: black;
}
.aboutUs{
    margin-top: 60px;
    width: 100%;
    background-color: rgb(18, 18, 18);
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-shrink: 1;
  }
  .aboutUs img {
  width: 44%;
  }
  .aboutUsContent {
    padding-top:60px;
    width: 50%;
    margin-left: 30px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
  }
  
  .aboutUsBlocks{
    display: flex;
    justify-content: center;
  }
  .aboutUsBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .regals{
    width: 100%;
  }
  .regalText{
    position: relative;
    display: flex;
    gap:55vh;
  }
  .proc {
    position: absolute;
    right: 40px;
  }
  @media (max-width:831px){
    .aboutUs img {
      width: 100%;
      }
      .aboutUsContent {
        justify-content: center;
        width: 85%;
      }
    .aboutUs{
      flex-direction: column;
      gap: 20px;
    }
  }
  @media (max-width:558px){
    .aboutUs{
      margin-top: 30px;
    }
    .proc {
      position: absolute;
      right: 20px;
    }
    .aboutUsContent p {
      font-size: 18px;
      text-align: start;
    }
  }
  .regalLine{
    width: 95%;
    background-color:#fde910 ;
    height: 5px;
  }
  
  .aboutUsBlockHead{
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .aboutUsBlockHead{
    width: 100px;
  }