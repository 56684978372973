
body {
  margin: 0;
  font-family:  "Sofia Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* h1,h2,h3,h4,h5 {
  font-family:  "Expletus Sans", sans-serif;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
