.mineSlider {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sliderStyles {
    padding-top: 95px;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
  @media(max-width:800px){
    .sliderStyles {
      padding-top: 20px;
    }
  }
  .slideStyles {
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    z-index: 0;
  }
  .slideStyles::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to bottom ,rgba(0, 0, 0, 0.6) 0%,rgba(1, 1, 1, 1) 200%);
  }
  .slideStyles img {
    width: 100%;
    height: 100%;
    
  }
  .innerSl{
      position:absolute;
      z-index:20;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      text-align: center;
      margin-left: 100px;
      margin-right: 100px;
  }
  .innerSl h1 {
    font-size:75px;
    font-family: "Oswald" ;
    font-weight: bold;
  }
  
  .innerSl button{
    cursor: pointer;
    width: 300px;
    height: 70px;
    color: black;
    background-color: #fde910;
    border: none;
    font-size: 20px;
  }
  @media(max-width:2000px){
    .innerSl h1 {
      font-size:60px;
      font-family: "Oswald" ;
      font-weight: bold;
    }
    .innerSl button{
      cursor: pointer;
      width: 250px;
      height: 60px;
      color: black;
      background-color: #fde910;
      border: none;
      font-size: 20px;
    }
    }
  .innerSl button:hover {
    color: white;
    background-color: black;
  }
  @media(max-width:1600px){
    .leftArrowStyles {
      width: 30px;
      height: 30px;
    }
    .rightArrowStyles {
      width: 30px;
      height: 30px;
    }
    .innerSl h1 {
      font-size:35px;
    }
    .innerSl button{
      cursor: pointer;
      width: 200px;
      height: 50px;
      color: black;
      background-color: #fde910;
      border: none;
      font-size: 15px;
    }
  }
  @media(max-width:780px){
    header{
      height: 70px;
    }
    .slideStyles img {
      height: 100%;
      margin-top: 70px;
    }
    .innerSl h1 {
      font-size:27px;
    }
    .innerSl h1 {
      font-size:18px;
    }
    .innerSl h3 {
      font-size:13px;
    }
    .innerSl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      text-align: center;
      margin-left: 0px;
      margin-right: 0px;
  }
    .innerSl button{
      cursor: pointer;
      width: 180px;
      height: 40px;
      font-size:12px;
    }
    .leftArrowStyles {
      display: none;
    }
    .rightArrowStyles {
      display: none;
      top: 50%;
      transform: translate(0, -50%);
      right: 14px;
      color: white;
      z-index: 2;
      cursor: pointer;
    }
  }
  @media(max-width:410px){

    .slideStyles img {
      margin-top: 70px;
      height: 100%;
    }
    .innerSl {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center;
        text-align: center;
        margin-left: 0px;
        margin-right: 0px;
    }
    .innerSl h1 {
      font-size:18px;
    }
    .innerSl button{
      cursor: pointer;
      width: 150px;
      height: 35px;
      font-size:12px;
      
    }
  }
  .leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    color: white;
    z-index: 2;
    cursor: pointer;
  }
  .rightArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 14px;
    color: white;
    z-index: 2;
    cursor: pointer;
  }
  .dotsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dots {
    z-index: 2;
    margin-top: -100px;
    font-size: 80px;
    color: white;
    cursor: pointer;
  }