.yellowBlock2{
    margin: 0 auto;
    margin-top:0px;
    flex-wrap: wrap;
    font-size: 25px;
    color: #000;
    padding-top: 40px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    background-color:   #fde910;
  }
  .yellowBlock2Content {
    width: 300px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .yellowBlock2Content img {
    filter: brightness(0) invert(0);
  
  }
  .yellowBlock2Content h1 {
    /* background-image: url(https://mebility.com.ua/product-category/mebel-v-nalichii/stoly-mebel-v-nalichii/konsolnye-stoly-stoly-mebel-v-nalichii/); замініть /path/to/image.jpg на шлях до вашого зображення */
    /* background-clip: text;
    -webkit-background-clip: text; /* для підтримки Safari та старіших браузерів */
    /*color: transparent; */
    font-size: 90px;
    color: black;
    font-weight: 900;
    /* margin: 67px 0px; */
  }

  @media (max-width:2000px){
    .yellowBlock2Content h1 {
      font-size: 70px;
      color: black;
      font-weight: 900;
      margin: 35px 0px;
    }
    }

    @media (max-width:1500px){
      .yellowBlock2Content h1 {
        font-size: 60px;
        color: black;
        font-weight: 900;
        margin: 25px 0px;
      }
      .yellowBlock2Content h4 {
        font-size: 20px;
       }
       .yellowBlock2Content img {
        width: 40px;
        height: 40px;
       }
      }
  
  @media (max-width:600px){
    .yellowBlock2{
      font-size: 20px;
      color: #000;
      padding-top: 10px;
    }
    .yellowBlock2Content {
      width: 250px;
      color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .yellowBlock2Content img {
      filter: brightness(0) invert(0);
    
    }
    .yellowBlock2Content h1 {
     font-size: 55px;
      color: black;
      font-weight: 900;
      margin: 25px 0px;
    }
    .yellowBlock2Content h4 {
      font-size: 18px;
     }
     .yellowBlock2Content img {
      width: 35px;
      height: 35px;
     }
    
  }
  