.wrapper{
  margin-top: 70px;
}
.h1 {
  font-size: 50px;
}
.gf{
  display: flex;
  gap: 50px;
}
@media(max-width:2000px){
  .wrapper{
    margin-top: 0px;
  }
  .sliderStyles h1 {
    color: white;
    font-size: 30px;
  }
}
.ourProjects{
    margin-top: 100px;
    padding-top: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
  }
  .ourItems{
    width: 100%;
    flex-wrap: wrap;
    /* margin-left: 10px; */
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }
  @media(max-width:2700px){
    .ourItems{
      gap: 80px;
     
    }
    .gf{
      gap: 80px;
    }
  }
  @media(max-width:1510px){
    .ourItems{
      gap: 40px;   
    }
    .gf{
      gap: 40px;
    }
  }
  @media(max-width:1340px){
    .gf{
      flex-direction: column;
    }
  }
  .ourItem {
    cursor: pointer;
    width: 400px;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.2s ease-in-out, transform 0.2s ease ;
    color: rgb(255, 255, 255);
    /* border-radius: 10px; */
    border: 1px solid #fde910;
  }
  .content{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 350px;
  }
  .ourItem1{
    background-color: #2a2a2a;
  }
  .ourItem:hover {
    transform: translateY(-7px);
  }
  .ourItem p {
    font-size: 16px;
    margin-top: -3px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
  }
  .ourItem h3{
    font-size: 30px;
    line-height: 20px;
  }
 
  /* .ourItem h4{
    color: #4a4a4a;
  }
   */
  .ourItemImg{
    /* border-radius: 10px; */
    height: 100%;
    mix-blend-mode: difference;
  }
  .ourItem:hover  .ourItemImg {
    height: 100%;
    mix-blend-mode: soft-light;
  }
  .ourItem button {
    margin-bottom: 25px;
    width: 200px;
    height: 45px;
    color: black;
    background-color: #fde910;
    border: none;
    margin-bottom: 20px;
    border-radius: 3px ;
    font-size: 17px;
  }
  .ourItem button:hover{
    cursor: pointer;
    color: white;
    background-color: black;
  }
  @media(max-width:500px){
    .ourItemImg{
      width: 320px;
    }
    .ourItem {
      border-radius: 5px;
      cursor: pointer;
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: box-shadow 0.2s ease-in-out, transform 0.2s ease ;
    }
  }
  /*-----------------------------------------------------------------------------------*/
  
  .sliderStyles {
    top: 20px;
    background-color: rgb(11, 11, 11);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
  
  .slideStyles {
    margin-left: 10px;
    margin-right: 10px;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-position: center;
    z-index: 0;
  }
  /* .slideStyles::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to bottom ,rgba(0, 0, 0, 0.6) 0%,rgba(1, 1, 1, 1) 100%);
   
  } */
 
    .slideStyles img {
      max-width: 1500px;
      height: 800px;
      border-radius: 6px;
    }

  @media(max-width:2000px){
    .slideStyles img {
      max-width: 1300px;
      height: 600px;
      border-radius: 6px;
    }
}
  @media(max-width:1400px){
    .slideStyles img  {
      max-width: 100%;
      border-radius: 6px;
    }
}
  .leftArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    color: white;
    z-index: 2;
    cursor: pointer;
  }
  .rightArrowStyles {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 14px;
    color: white;
    z-index: 2;
    cursor: pointer;
  }
  @media(max-width:1200px){
    .slideStyles img  {
      height: 500px;
    }
}
  @media(max-width:700px){
    .slideStyles img  {
      height: 290px;
      border-radius: 0;
    }
    .leftArrowStyles {
      /* display: none; */
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      left: 10px;
      color: white;
      z-index: 2;
      cursor: pointer;
    }
    .ourItem h3{
      font-size: 23px;
      line-height: 20px;
    }
    .rightArrowStyles {
      /* display: none; */
     
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 14px;
      color: white;
      z-index: 2;
      cursor: pointer;
      
    }
    .sliderStyles h1 {
      font-size: 18px;
    }
  }
  .dotsContainer {
    width: 1000px;
    height: 150px;
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
    text-align: center;
}
  @media(max-width:1020px){
    .dotsContainer {
      width: 800px;
      height: 120px;
    }
    .dotsContainer img {
      height: 100px;
    }
  }
  @media(max-width:820px) {

    .sliderStyles{
       margin-top:20px;
    }
    .dotsContainer {
      width: 600px;
    }
  }
  @media(max-width:620px){
    .sliderStyles {
      margin-top:30px;
    }
    .dotsContainer {
      height: 90px;
      width: 400px;
    }
    .dotsContainer img {
      height: 70px;
      width: 140px;
    }
  }
  @media(max-width:420px){
    .dotsContainer {
      width: 290px;
    }
  }
  .dots {
    filter: brightness(20%);
    margin-top: 15px;
    z-index: 2;
    font-size: 80px;
    color: white;
    cursor: pointer;
    border-radius: 5%;
  }
  .activeDot{
    filter: brightness(100%);
  }
  