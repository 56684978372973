.wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-top: 10px;
} 
.container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    background-color: black;
}
.header_line{
    background-color: #fde910;
    width: 90%;
    height: 1px;
    margin-top: 10px;
}
.header_title{
    font-size: 40px;
}
.item1{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(126, 126, 126);
}
.item2{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(97, 97, 97);
}
.item3{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(43, 43, 43);
}
.item4{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(19, 19, 19);
}
.item5{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(5, 5, 5);
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header_numder{
    font-size: 100px;
} 
ul li , ul p {
    font-size: 30px;
}
@media(max-width:2000px){
    ul li , ul p {
        font-size: 20px;
    }
    .header_numder{
        font-size: 80px;
    }  
    .header_title{
        font-size: 30px;
    }
}
@media(max-width:1200px){
    ul li , ul p {
        font-size: 18px;
    }
    .header_numder{
        font-size: 70px;
    }  
    .header_title{
        font-size: 20px;
    }
}

@media(max-width:800px){
    ul li , ul p {
        font-size: 12px;
    }
    .header_numder{
        font-size: 50px;
    }  
    .header_title{
        font-size: 15px;
    }
}

@media(max-width:600px){
    ul li{
      list-style: none;  
    }
    ul {
        padding: 7px;
    }
    .header_title{
        font-size: 13px;
    }
}

@media(max-width:480px){
    ul li{
      list-style: none;  
    }
    ul {
        padding: 5px;
    }
    ul li , ul p {
        font-size: 10px;
    }
    .header_numder{
        font-size: 35px;
    }  
    .header_title{
        font-size: 9px;
    }

}
@media(max-width:405px){
    ul li{
      list-style: none;  
    }
    ul {
        padding: 4px;
    }
    ul li , ul p {
        font-size: 9px;
    }
    .header_numder{
        font-size: 30px;
    }  
    .header_title{
        font-size: 10px;
    }

}
@media(max-width:386px){
    ul li{
      list-style: none;  
    }
    ul {
        padding: 7px;
    }
    ul li , ul p {
        font-size: 6px;
    }
    .header_numder{
        font-size: 30px;
    }  
    .header_title{
        font-size: 8px;
    }

}
.item1,.item2,.item3,.item4,.item5 ul{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

}
.item1:hover,.item2:hover,.item3:hover,.item4:hover,.item5:hover{
    background-color: #fde910;
    color: black;
}

.item1:hover .header_line,.item2:hover .header_line,.item3:hover .header_line,.item4:hover .header_line,.item5:hover .header_line {
    background-color: black;
}
