footer{
    position: relative;
    bottom:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(211, 211, 211);
    flex-shrink: 0;
    z-index: 11;
}
.footer_container{
    margin-top:50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 20px;
    gap: 300px;
}
@media(max-width:1670px){
    .footer_container{
       gap: 200px;
    }
}
.firstBlock {
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
}
.logo_promo{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
}
.firstBlock h3 {
    letter-spacing: 3px;
    font-family: "El Messiri";
    white-space: nowrap;
    text-transform: uppercase;
    font-family: "Kalam";
    white-space: nowrap;
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 24px;
    text-transform: uppercase;
    color: black;
}
.firstBlock h4 {
    letter-spacing: 3px;
    font-family: "Catamaran";
    line-height: 20px;
    margin-left: 10px;
    margin-top: -38px;
    padding-top: 100 px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: black;
}
.firstBlock p {
    color: black;
}
.firstBlock  button {
    cursor: pointer;
    width: 220px;
    height: 50px;
    color: white;
    background-color:black; 
    border: none;
}
.firstBlock  button:hover{
    background-color:rgb(41, 41, 41); 
}
.secondBlock {
    color: black;
    font-size: 25px;
}
.threeBlock {
  display: flex;
  gap:10px
}
@media(max-width:1400px){
    .footer_container{
       gap: 100px;
    }
}
@media(max-width:1200px){
    .footer_container{
       gap: 50px;
    }
    .logo_promo h3 {
        font-size: 30px;
    }
    .logo_promo h4 {
        font-size: 10px;
    }
    .secondBlock {
        color: black;
        font-size: 14px;
    }
}
@media(max-width:900px){
    .footer_container{
       flex-direction: column;
       gap: 20px;
    }
    .logo_promo h3 {
        font-size: 30px;
    }
    .logo_promo h4 {
        font-size: 10px;
    }
}
iframe{
    width: 99.5%;
}
@media(max-width:400px){
    iframe{
        width: 99%;
    }
    .footer_container{
        margin-left:0px ;
        margin-right:0px ;
    }
}