/* .conect{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 100px;
    height: 100px;
    background-color: rgb(255, 255, 0);
    border-radius: 50%;
    z-index: 10;
  } */
  .cont{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    width: 80px;
    height: 80px;
    background-color: rgb(30, 30, 30);
  }
  .cont:hover{
    background-color: rgb(44, 44, 44);
  }
  .cont img {
    width: 50px;
    height: 50px;
  }
  @media(max-width:2000px){
    .cont{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 10;
    width: 60px;
    height: 60px;
    background-color: rgb(30, 30, 30);
  }
  .cont img {
    width: 30px;
    height: 30px;
  }
  
}
@media(max-width:500px){
  .cont{
  width: 50px;
  height: 50px;
  background-color: rgb(30, 30, 30);
}
.cont img {
  width: 30px;
  height: 30px;
}
}