.wrapper{
    margin-top: 100px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media(max-width:2000px){
    .wrapper{
      margin-top: 0px;
    }
  }
.content{
    display: flex;
    flex-direction:column;
    align-items: center;
    margin-top: 120px;
   
}
button{
    cursor: pointer;
    height: 60px;
    width: 300px;
    font-size: 20px;
    color: white;
    background-color:#fde910;
    border:none;
    color: black;
}
button:hover{
    background-color: rgb(163, 163, 15);
}
   
/* @media(max-width:1130px){
    .content{
        display: flex;
        align-items: center;
        gap: 100px;
       
    }
} */
/* @media(max-width:1000px){
    .content{
        flex-direction: column-reverse;
        gap: 0px;
    }
    
} */
.contWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.linkSocial img {
    filter: brightness(0) 
}
.contWrapper a:hover{
    color: blue;
}
.greyBlock{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    background-color: rgb(13, 13, 13);
    padding-top: 20px;
    padding-bottom: 20px;
}
.greyBlock:hover {
    background-color:#fde910;
    color: black;
}
.greyBlock:hover h1 {
    color: black;
}
.h1{
    font-size: 25px;
    margin-top: 20px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: justify;
    color: white;
   
}
.contacts{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 115px;
}
.contact{
    display: flex;
    flex-direction: column
}
.contact h2 {
    font-size: 30px;
}
.contact a {
    color: rgb(135, 135, 0);
    text-decoration: none;
    font-size: 23px;
}
form {
    width: 1000px;
    display: flex;
    flex-direction: column;
    gap:20px;
    align-items: center;
    justify-content: center;
    background-color:black;
    padding-bottom:100px;
}
textarea{
    width:1000px;
    height:100px;
    color:rgb(255, 255, 255);
    font-size:17px;
    background-color:rgb(22, 22, 22);
    border:none;
    padding-left: 5px;

}
.item input {
    width:1000px;
    height:50px;
    color:rgb(255, 255, 255);
    font-size:17px;
    background-color:rgb(22, 22, 22);
    border:none;
    padding-left: 5px;
}
@media(max-width:1050px){
    button{
        cursor: pointer;
        height: 45px;
        width: 250px;
        font-size: 17px;
        color: white;
        background-color:#fde910;
        border:none;
        color: black;
    }
    .contact h2 {
        font-size: 25px;
    }
    .contact a {
        color: rgb(135, 135, 0);
        text-decoration: none;
        font-size: 18px;
    }
    .contacts{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 30px;
        
    }
    .contact{
        display: flex;
        flex-direction: column
    }
    form{
        padding-top:0px;
        padding-bottom:20px;
    }
    textarea{
        width:700px;
    }
    .item input {
        width:700px;
        
    }
    .greyBlock{
       width:700px;
       height: 300px;
    }
    form {
        width: 700px;
        
    }
}
.item{
    display:flex;
    flex-direction:column;
    margin-left:20px;
    margin-right:20px;
}
.item label{
    color:grey;
}

@media(max-width:725px){
    /* .item select,.item select option{
        width:297px;
    }; */
    form{
        width:420px;
    }
    textarea{
        width:420px;  
    }
    .item input{
        width:420px;
    }
    .greyBlock{
        width:430px;
        height: 250px;
     }
    .h1{
        font-size: 18px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;       
    }
    .contacts{
        flex-direction: column;
        align-items: center;
        gap: 0;
        width:300px;
     }
     .contact{
         align-items: center;
     }
}

@media(max-width:525px){
    .content{
        margin-top: 80px;
       
    }
    /* .item select,.item select option{
        width:297px;
    }; */
    form{
        width:320px;
    }
    textarea{
        width:320px;  
    }
    .item input{
        width:320px;
    }
    .greyBlock{
        width:330px;
        height: 300px;
     }
    .h1{
        font-size: 18px;
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;       
    }
    .contWrapper a {
      font-size: 14px;
    }
}

/* @media(max-width:410px){ */
    /* .item select,.item select option{
        width:297px;
    }; */
    /* .item input{
        width:247px;
    } */


