.conect{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 100px;
    height: 100px;
    background-color: rgb(215, 212, 0);
    border-radius: 50%;
    z-index: 10;
  }
  .conect:hover{
    background-color: rgb(169, 169, 0);
  }
  .Cont{
    cursor: pointer;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    gap: 10px;
    z-index: 10;
  }
  .conect1 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: rgb(215, 212, 0);
    border-radius: 50%;
  }
  .items {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100px;
    height: 190px;
    background-color: rgb(255, 255, 255);
    border-radius: 10%;
  }
  @media(max-width:2000px){
    .items{
      width: 60px;
      height: 100px;
    }
    .items img {
      width: 40px;
      height: 40px;
    }
    .conect1, .conect {
      width: 60px;
      height: 60px;
    }
    .conect img, .conect1 img {
      width: 25px;
      height: 25px;
    }
  
  }