.ourServices{
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .ourServices{
    font-size: 30px;
  }
 .h1{
  font-size: 50px;
 }
 .service h3 {
  font-size: 40px;
} 
  .service{
    width: 1000px;
    height: 750px;
    background-color: #111111;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 40px;
    border: 1px #fde910 solid;
    /* margin-left:100px ;
    margin-right:100px ; */
  }
  .services{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 70px;
    justify-content: center;
    align-items: center;
  }
  .service p {
    font-size: 35px;
    text-align: justify;
    list-style: none;
  }
  .serviceHeader img {
    height: 40px;
    width: 40px;
    margin-right: 5px;
  }
  
@media(max-width:2350px){
  .service h3 {
    font-size: 35px;
  } 
  .service{
    width: 800px;
    height: 700px;
    
  }
  .service p {
    font-size: 30px;
  }

}
@media(max-width:2000px){
  .ourServices{
    margin-top: 0px;
}
}
  @media(max-width:1900px){
    .services{
      display: flex;
      gap: 60px;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    .h1{
      font-size: 30px;
     }
    .service{
      width: 700px;
      height: 500px;  
    }
    .service p {
      font-size: 25px;
      text-align: justify;
    }
    .service h3 {
      font-size: 30px;
    } 
    .serviceHeader img {
      height: 30px;
      width: 30px;
      margin-right: 5px;
    }
  }
  
  @media(max-width:1700px){
    .service h3 {
      font-size: 25px;
    } 
    .ourServices{
      margin-top: 0px;
    }
    .service p {
      font-size: 22px;
      text-align: justify;
    }
    .service{
      width:600px;
    }
    .serviceHeader img {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }
  }
  @media(max-width:1500px){
    .service h3 {
      font-size: 23px;
    } 
    .ourServices{
      margin-top: 0px;
    }
    .service p {
      font-size: 20px;
      text-align: justify;
    }
    .service{
      width:500px;
       height: 450px;
    }
    .serviceHeader img {
      height: 25px;
      width: 25px;
      margin-right: 5px;
    }
  }
  @media(max-width:1300px){
    .services {
      gap: 30px;
    }
  }
  @media(max-width:1250px){
    .services {
      gap: 50px;
      margin-left: 20px;
      margin-right: 20px;

    }
    .service{
      justify-content: flex-start;
      align-items: flex-start;
      padding-left: 65px;
      padding-top:20px;
      padding-bottom: 20px;
      width:400px;
      height: 600px;
      margin-bottom: 0px;
    }
    .service p {
      font-size: 20px;
      text-align: start;
    }
  }
  
  /* .service p {
    font-size: 22px;
    display: flex;
    flex-direction: column;
    list-style: none;
  } */
  .service:hover{
    background-color: #fde910;
    color: #000;
    
  }
  .serviceHeader{
    gap: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .service:hover .serviceHeader img {
    filter: brightness(0) invert(0);
  }
   /* @media(max-width:3000px){
    .service{
      width: 30%;
    }
  }
  @media(max-width:2000px){
    .service{
      width: 540px;
      margin-left: 20px;
      margin-right: 20px;
     
    }
  } */
  @media(max-width:1100px){
    .h1{
      font-size: 30px;
     }
    .serviceHeader img {
      height: 25px;
      width: 25px;
      margin-right: 0px;
    }
    .services {
      flex-direction: column;
      gap: 40px;
    }
    .service{
      padding: 30px;
      align-items: center;
      width: 80%;
      height: 100%;
      margin-bottom: 0px;
    }
    .service p {
      font-size: 20px;
      text-align: justify;
    }

  }
  
  @media(max-width:750px){
    .h1{
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 20px;
    }
    .service{
      margin-left: 15px;
      margin-right: 15px;
     
    }
    .service h3 {
      font-size: 22px;
    } 
    .service p {
      font-size: 20px;
      text-align: justify;
    }
    .ourServices{
      font-size: 20px;
    }
  }
  @media(max-width:624px){

    .service{
      margin-left: 15px;
      margin-right: 15px;
      padding-left: 30px;
      padding-right: 10px;
    }
    .service h3 {
      font-size: 18px;
    } 
    .service p {
      font-size: 17px;
      text-align: justify;
    }
    .ourServices{
      font-size: 20px;
    }
  }
  @media(max-width:490px){
    .h1{
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 20px;
    }
    .services{
      margin: 0;
    }
    .ourServices {
      gap: 0px;
    }
    .service{
      margin-left: 5px;
      margin-right: 5px;
      padding-left: 35px;
      padding-top:20px;
      padding-bottom: 20px;
      width: 290px;
      align-items: flex-start;
    }
    .service h3 {
      text-align: start;
      font-size: 19px;
    } 
    .service p {
      margin-left: 10px;
      margin-right: 0px;
      font-size: 17px;
      text-align: start;
    }
    .serviceHeader{
      margin-left: 10px ;
    }
  }