.sliderStyles2{
  margin: 0 auto;
    padding-bottom: 50px;
    max-width: 1400px;
    min-height: 150px;
}
.slideItem{
    margin-top: 80px;
    margin-left: 300px;
    margin-right: 300px;
    display:flex;
    text-align:center;
    flex-direction:column;
    align-items:center;
    justify-content: center;
  }
  .slideItem img {
    border-radius:50%;
  }
  .leftArrowStyles2,.rightArrowStyles2 {
    display: none;
    position: relative;
    bottom:140px;
    left: 80px;;
  }
  .dotsContainer{
    display: flex;
    margin-left: 670px;
    margin-top: -160px;
  }
  .dots{
    display: none;
    font-size: 50px;
  }
  .slideItem p {
    font-size: 20px;
  }
  .slideItem h4 {
    font-size: 25px;
  }