.wrapper{
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    border: none; 
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}
.container{
        position: absolute;
        top: 20%;
        gap: 30px;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 700px;
        height: 400px;
        background-color:rgb(2, 0, 0);
        border-left: 1px solid #eaeaea;
        z-index: 1;
        border: none; 
        z-index: 2;
        cursor: pointer;
}
.form{
width: 100%;
width: 100%;
background-color: black;
}
.container img{
   width: 30px;
   height: 30px;
   margin-top: 10px;
   margin-left: 630px;
}
.container button {
    margin-top: 10px;
    width: 200px;
    height: 45px;
    background:  #fde910;
    border: none;
    color: rgb(0, 0, 0);
}
.container button:hover {
    background: rgb(108, 108, 0);
}
/*_______________________________________________*/

.h1{
    color: rgb(96, 96, 96);
    margin-left: 50px;
    margin-right: 30px;
    margin-bottom: -10px;
}
@media(max-width:1000px){
    .h1{
        margin-bottom: -10px;
    }
    .form{
        padding-bottom: 50px;
    }
}
.item{
    display:flex;
    flex-direction:column;
    margin-left:20px;
    margin-right:20px;
}
.item label{
    color:grey;
}
.item input {
    width:600px;
    height:50px;
    color:rgb(255, 255, 255);
    font-size:17px;
    background-color:rgb(22, 22, 22);
    border:none;
    padding-left: 5px;
}
@media(max-width:754px){
    .container{
        width: 350px;
    }
    .container img{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 300px;
     }     
    .item input{
        width:280px
    }
    .h1{
        font-size: 17px;
        color: rgb(96, 96, 96);
        margin-left: 35px;
        margin-right: 20px;
    }
}
@media(max-width:415px){
    .container{
        width: 350px;
    }
    .container img{
        width: 20px;
        height: 20px;
        margin-top: 15px;
        margin-left: 285px;
     }     
    .item input{
        width:280px
    }
    .h1{
        font-size: 17px;
        color: rgb(96, 96, 96);
        margin-left: 30px;
        margin-right: 20px;
    }
}
button{
    cursor: pointer;
    height: 50px;
    width: 300px;
    font-size: 17px;
    color: white;
    background-color: rgb(135, 135, 0);
    border:none;
    color: black;
}
button:hover{
    background-color: rgb(255, 255, 31);
}
   