.wrapper {
    margin-top: 200px;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
}
@media(max-width:2000px){
    .wrapper{
    margin-top: 120px;
    }
}
.gallery{
   gap: 20px;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   padding-bottom: 20px;
   padding-top: 50px;
}
.img {
    width:1500px;
    height: 1000px;
}
.imgV {
    width:700px;
    height: 1000px;
}
.activeCont{
    position: absolute;
    background-color: black;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: unset;
}
.activeImg {
position: fixed;
top:10%;
width:1800px;
height: 1200px;
z-index: 10000000;
}
.activeImgV{
        position: fixed;
         top:10%;
        width:900px;
        height: 1200px;
        z-index: 10000000;
}
/*--------------------------------------------------------*/
.dot{
    color: black;
  }
  .aboutUs{
      margin-top: 60px;
      width: 1500px;
      background-color: rgb(18, 18, 18);
      display: flex;
      justify-content: center;
      margin: 0 auto;
      /* border: 1px #fde910 solid; */
      flex-shrink: 1;
    }
    .aboutUsContent {
      padding-top:60px;
      margin-left: 30px;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 40px;
    }
    .aboutUsContent p {
      font-size: 30px;
      display: flex;
      flex-direction: column;
      text-align: justify;
      gap: 20px;
    }
    .aboutUsContent h1 {
       font-size:40px ;
    }
    .aboutUsContent p li {
        list-style: none;
    }
    .button{
        width: 260px;
        height: 55px;
        font-size: 17px;
    }
@media(max-width:2000px) {
    .aboutUsContent p  {
        font-size: 25px;
       
        }
        .button {
            width: 240px;
            height: 50px;
            font-size: 16px;
      }
    .activeImg {
    position: fixed;
    top:20px;
    max-width:1400px;
    height: 800px;
    z-index: 10000000;
    }
    .activeImgV{
        position: fixed;
         top:10px;
        width:700px;
        height: 900px;
        z-index: 10000000;
}
    .img{
        width: 1300px;
        height: 800px;
    }
    .imgV {
        width:600px;
        height: 700px;
    }
    .aboutUs{
        width: 1300px;
      }
    }
@media(max-width:1600px) {
.img{
    max-width:1200px;
    height: 700px;
}
.imgV {
    width:500px;
    height: 600px;
}
.activeImg {
    position: fixed;
    top:25px;
    max-width:1200px;
    height: 700px;
    z-index: 10000000;
    }
    .aboutUs{
        width: 1200px;
      }
      .activeImgV{
        position: fixed;
         top:10px;
        width:500px;
        height: 600px;
        z-index: 10000000;
}
}
@media(max-width:1500px) {
    .img{
        width: 1300px;
    }
    .aboutUs{
        width:1300px;
    }
.activeImg {
    position: fixed;
    top:3%;
    width:1300px;
    height: 700px;
    z-index: 10000000;
    }
}
@media(max-width:1300px) {
    .activeImg {
        position: fixed;
        top:10px;
        width:1000px;
        height: 640px;
        z-index: 10000000;
        }
    }
    @media(max-width:1300px) {
        .img{
            height: 700px;
            width: 1070px;
        }
        .imgV {
            width:700px;
            height: 900px;
        }
        .aboutUs{
            width: 1070px;
        }
    }
@media(max-width:1070px) {
    .img{
        height: 600px;
        width: 780px;
    }
    .imgV {
        width:600px;
        height: 800px;
    }
    .activeImgV{
        width:400px;
        height: 500px;
}
    .aboutUs{
        width: 780px;
    }
    .activeImg {
        position: fixed;
        top:30px;
        width: 100%;
        height: 600px;
        z-index: 10000000;
        }
    }

@media(max-width:780px) {
    .img{
        height: 450px;
        width: 100%;
    }
    .aboutUs{
        width: 100%;
    }
    .activeImg {
        top:15%;
        height: 450px;
    }
    .activeImgV{
        width:400px;
        height: 500px;
}
}
@media(max-width:650px) {
    .button {
        width: 180px;
        height: 45px;
        font-size: 14px;
       }
    .aboutUsContent p {
        font-size: 18px;
      }
      .aboutUsContent h1 {
         font-size:20px ;
      }
    .activeImg {
        top:15%;
        height: 380px;
    }
    .activeImgV{
        width:400px;
        height: 600px;
}
}
@media(max-width:600px) {
    .aboutUsContent {
        padding-left: 10px;
    }
    .img{
        height: 300px;
    }
    .activeImg {
        top:20%;
        height: 300px;
    }
    .imgV {
        width:340px;
        height: 500px;
    }
    .activeImgV{
        width:340px;
        height: 600px;
}

@media(max-width:490px){
    .aboutUsContent{
        padding-left: 20px;
      margin-left: 5px;
      margin-right: 5px;
      /* width: 300px; */
      align-items: flex-start;
    }
    .aboutUsContent {
      text-align: start;
      font-size: 20px;
    } 
    .aboutUsContent p {
      margin-left: 0px;
      margin-right: 0px;
      font-size: 17px;
      text-align: start;
    }
  }
  

}
@media(max-width:400px) {
    .img{
        height: 250px;
    }
    .activeImg {
        height: 250px;
    }
}
