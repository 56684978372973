main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* змінюємо розміри контентного блоку */
    min-height: calc(100vh - 300px); /* 60px - висота футера */
    padding-bottom: 0px; /* 300px - висота футера */
    box-sizing: border-box;
    flex-shrink: 1;
    margin: 0 auto;
}
.container{
  max-width:100%;
}

  
  /*------------------------------------------------------*/